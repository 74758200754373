import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import {
  withContext,
  combineEventHandlers,
  eventBus,
  EventBusType,
} from '@piggybank/core';

import FormikForm from './FormikForm';

import { Consumer } from '../Wizard/context';

const Form = ({
  enableReinitialize = true,
  isInitialValid,
  initialValues,
  onReset,
  onSubmit = () => {},
  setInitialValues = (initialValues) => initialValues,
  validate,
  validateOnBlur,
  validateOnChange,
  validationSchema,
  debounceWait = 250,
  textMap = { errorAnnouncementPrefix: 'Error: ' },
  ...props
}) => (
  <Formik
    enableReinitialize={enableReinitialize}
    isInitialValid={isInitialValid}
    initialValues={setInitialValues(initialValues)}
    initialStatus={{ debounceWait }}
    onReset={onReset}
    onSubmit={async (values, formikBag) => {
      formikBag.setSubmitting(true);
      eventBus.dispatch({
        type: EventBusType.ON_SUBMIT,
        component: 'Form',
        values: values,
        name: props.name,
      });
      await onSubmit({ values, formikBag });
      formikBag.setSubmitting(false);
    }}
    validate={validate}
    validateOnBlur={validateOnBlur}
    validateOnChange={validateOnChange}
    validationSchema={validationSchema}
  >
    {(formikProps) => (
      <FormikForm formik={formikProps} textMap={textMap} {...props} />
    )}
  </Formik>
);

Form.displayName = 'Form';

Form.propTypes = {
  enableReinitialize: PropTypes.bool,
  isInitialValid: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  onReset: PropTypes.func,
  /** docgen-from-context:<Wizard/> */
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  validationSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  textMap: PropTypes.shape({
    errorAnnouncementPrefix: PropTypes.string,
  }),
  /** docgen-from-context:<Wizard/> */
  setInitialValues: PropTypes.func,
  /** docgen-from-context:<Wizard/> */
  debouncedOnChange: PropTypes.func,
  onError: PropTypes.func,
  debounceWait: PropTypes.number,
  name: PropTypes.string,
};

export { Form };

export default withContext(Consumer, (value, ownProps) => ({
  onSubmit: combineEventHandlers(ownProps.onSubmit, value.onSubmit),
  setInitialValues: value.setInitialValues,
  debouncedOnChange: value.setUnsubmittedValues,
}))(Form);
