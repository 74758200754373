import React from 'react';
import Theme from '../../../utils/Theme';

const theme = Theme.select({
  redbrand: 'redbrand',
  mands: 'mands',
  blackbrand: 'blackbrand',
});

const SvgComponent = (props) => (
  <>
    {(theme === 'redbrand' || theme === 'blackbrand') && (
      <svg viewBox="0 0 18 18" width="1em" height="1em" {...props}>
        <path d="M15 1V0h-1.2v1H4.2V0H3v1H0v17h14.2l3.8-3.8V1h-3zm-.8 15.303V14.2h2.103L14.2 16.303zM16.8 13H13v3.8H1.2V6h15.6v7zm0-8.2H1.2V2.2H3v1.3h1.2V2.2h9.6v1.3H15V2.2h1.8v2.6zm-8 5.229V15H10V7.971L7.003 9.667l.591 1.045 1.206-.683z" />
      </svg>
    )}

    {theme === 'mands' && (
      <svg viewBox="0 0 18 18" width="1em" height="1em" {...props}>
        <path d="M11.56 11.733c0 .644-.12 1.15-.358 1.516-.24.367-.623.551-1.15.552-.282 0-.562-.043-.83-.129-.27-.085-.608-.213-1.014-.385l-.206-.086a1.656 1.656 0 0 1-1.303.6c-.593 0-.89-.271-.89-.814a.903.903 0 0 1 .364-.743c.242-.193.587-.29 1.036-.29.23.003.458.02.684.054a2.904 2.904 0 0 0-.195-.6c-.086-.193-.22-.457-.402-.793l-.13-.235H6.154v-.15h.934a5.332 5.332 0 0 1-.315-.765 2.359 2.359 0 0 1-.108-.713c-.004-.381.11-.755.325-1.071.226-.33.539-.593.904-.761.4-.189.837-.284 1.28-.278.363-.006.723.067 1.054.214.295.13.549.337.733.6.177.259.27.565.266.878 0 .315-.082.562-.244.743a.834.834 0 0 1-.657.274.774.774 0 0 1-.606-.22.753.753 0 0 1-.222-.598.786.786 0 0 1 .108-.428c.085-.13.186-.249.301-.354.077-.067.146-.142.207-.223a.335.335 0 0 0 .064-.197.452.452 0 0 0-.244-.397 1.228 1.228 0 0 0-.646-.149c-.362 0-.638.124-.828.372-.19.247-.286.612-.288 1.092 0 .224.018.448.055.67.036.218.087.469.15.755.058.243.098.425.12.547h1.608v.148H8.524c.047.29.072.584.075.879.003.298-.03.595-.098.885l.271.054c.363.078.68.137.95.176.268.04.538.06.809.06.29 0 .507-.074.651-.223.144-.149.217-.354.218-.616a3.58 3.58 0 0 0-.037-.492l.141-.01c.035.215.054.433.056.651zm-4.228 1.774c.174-.094.318-.233.418-.402-.42-.165-.767-.247-1.042-.247-.189 0-.33.038-.424.113a.37.37 0 0 0-.14.305c0 .248.184.372.553.372.22.007.439-.042.635-.141zM13.721 3.08a.713.713 0 0 1-.712.652.713.713 0 0 1-.712-.653V1.067a.71.71 0 0 1 .338-.664c.23-.14.519-.14.748 0 .23.14.36.397.338.664v2.012zM5.906 3.08a.713.713 0 0 1-.712.652.713.713 0 0 1-.712-.653V1.067a.713.713 0 0 1 .712-.653c.372 0 .681.284.712.653v2.012z" />
        <path d="M16.79 2.21h-2.513v.87a1.267 1.267 0 0 1-1.268 1.185c-.67 0-1.226-.52-1.267-1.186v-.868h-5.28v.868a1.267 1.267 0 0 1-1.268 1.186c-.671 0-1.227-.52-1.268-1.186v-.868H1.358c-.702 0-1.27.565-1.27 1.263V16.58c0 .697.568 1.263 1.27 1.263h15.431c.701 0 1.27-.566 1.27-1.263V3.474c0-.698-.569-1.263-1.27-1.263zm0 14.37H1.357V5.188h15.431V16.58z" />
      </svg>
    )}
  </>
);

export default SvgComponent;
