import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './playback-list.css';

export const PlaybackListType = {
  REVIEW: 'review',
  LARGE: 'large',
  STRONG: 'strong',
};

const playbackListPropTypes = {
  type: PropTypes.oneOf([
    PlaybackListType.REVIEW,
    PlaybackListType.LARGE,
    PlaybackListType.STRONG,
  ]),
  children: PropTypes.node,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

const PlaybackList = ({ type, children, marginBottom = 0, ...rest }) => (
  <dl
    className={cn(
      styles.PlaybackList,
      { [styles[`PlaybackList--${type}`]]: type },
      getMarginBottomClass(marginBottom)
    )}
    {...rest}
  >
    {children}
  </dl>
);

PlaybackList.displayName = 'PlaybackList';

PlaybackList.propTypes = { ...playbackListPropTypes };

const PlaybackKey = ({ type, children, marginBottom = 0, ...rest }) => (
  <dt
    className={cn(
      styles.PlaybackKey,
      { [styles[`PlaybackKey--${type}`]]: type },
      getMarginBottomClass(marginBottom)
    )}
    {...rest}
  >
    {children}
  </dt>
);

PlaybackKey.displayName = 'PlaybackKey';
PlaybackKey.propTypes = { ...playbackListPropTypes };

const PlaybackValue = ({ type, children, marginBottom = 4, ...rest }) => (
  <dd
    className={cn(
      styles.PlaybackValue,
      { [styles[`PlaybackValue--${type}`]]: type },
      getMarginBottomClass(marginBottom)
    )}
    {...rest}
  >
    {children}
  </dd>
);

PlaybackValue.displayName = 'PlaybackValue';
PlaybackValue.propTypes = { ...playbackListPropTypes };

export { PlaybackList, PlaybackKey, PlaybackValue };
