const listeners = new Map();

export const subscribe = (listener) => {
  const key = Symbol();
  listeners.set(key, listener);

  const unsubscribe = () => {
    listeners.delete(key);
  };

  return unsubscribe;
};

export const dispatch = (action) => {
  for (let [, listener] of listeners) {
    listener(action);
  }
};

export const EventBusType = {
  ON_NAVIGATE: 'onNavigate',
  ON_OPEN: 'onOpen',
  ON_CLOSE: 'onClose',
  ON_CLICK: 'onClick',
  ON_REMOVE: 'onRemove',
  ON_ADD: 'onAdd',
  ON_CHANGE: 'onChange',
  ON_ERROR: 'onError',
  ON_BLUR: 'onBlur',
  ON_CANCEL_EDIT: 'onCancelEdit',
  ON_SUBMIT: 'onSubmit',
};

export default {
  subscribe,
  dispatch,
};
