import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  IconCheckbox,
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
  eventBus,
  EventBusType,
} from '@piggybank/core';
import { Consumer } from '../Field/context';
import { Provider } from '../Label/context';

import styles from './checkbox.css';

const Checkbox = React.forwardRef(
  (
    {
      name,
      onChange,
      checked = false,
      invalid = false,
      describers,
      marginBottom = 0,
      children,
      disabled,
      ...rest
    },
    ref
  ) => {
    const id = `${name}-checkbox`;

    const handleChange = (e) => {
      eventBus.dispatch({
        type: EventBusType.ON_CHANGE,
        component: 'Checkbox',
        name,
        value: !!e.target.checked,
      });
      onChange({ event: e, value: !!e.target.checked });
    };

    return (
      <div
        className={cn(styles.Checkbox, getMarginBottomClass(marginBottom), {
          [styles['Checkbox--disabled']]: disabled,
        })}
      >
        <div className={cn(styles.inputContainer)}>
          <input
            aria-invalid={invalid}
            aria-describedby={describers}
            {...(disabled ? { 'aria-disabled': disabled, disabled } : {})}
            className={styles.input}
            type="checkbox"
            name={name}
            id={id}
            checked={checked}
            onChange={handleChange}
            ref={ref}
            {...rest}
          />
          <div
            className={cn(styles.inputMask, {
              [styles['inputMask--checked']]: checked,
              [styles['inputMask--invalid']]: invalid,
            })}
          >
            <span className={styles.icon}>
              <IconCheckbox width="1.125em" marginBottom={0} />
            </span>
          </div>
        </div>
        {children && (
          <label className={styles.label} htmlFor={id}>
            <Provider value={{ inLabel: true }}>{children}</Provider>
          </label>
        )}
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  /** docgen-from-context:<Field/> */
  name: PropTypes.string.isRequired,
  /** docgen-from-context:<Field/> */
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  /** docgen-from-context:<Field/> */
  checked: PropTypes.bool.isRequired,
  /**
   * docgen-from-context:<Field/>
   * ---
   * proxied to eventBus
   */
  onChange: PropTypes.func.isRequired,
  /** docgen-from-context:<Field/> */
  onBlur: PropTypes.func,
  /** docgen-from-context:<Field/> */
  describers: PropTypes.string,
  children: PropTypes.node,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

Checkbox.defaultProps = {
  marginBottom: 0,
  disabled: false,
};

export { Checkbox };

export default withContext(Consumer, (value) => ({
  name: value.name,
  checked: value.value,
  onChange: value.onChange,
  onBlur: value.onBlur,
  invalid: value.invalid,
  describers: value.describers,
}))(Checkbox);
