import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js/core';
import memoizeOne from 'memoize-one';

import countries from 'i18n-iso-countries';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import sortBy from 'lodash/fp/sortBy';

import isBlurringOutsideRefs from '../../../utils/isBlurringOutsideRefs';
import {
  getCountryCodes,
  getFormattedValues,
  checkPartialNumberChangesCountry,
} from './utils';
import {
  IconChevronDown,
  getMarginBottomClass,
  marginBottomLevels,
  eventBus,
  EventBusType,
} from '@piggybank/core';

import enLocale from 'i18n-iso-countries/langs/en.json';

import { parseCountry } from '../../../utils/parseCountry.js';

import { TextInput } from '../../TextInput';

import styles from './telephone-input.css';

const parsedEnCountry = parseCountry(enLocale);
countries.registerLocale(parsedEnCountry);

const memoizedParseNumber = memoizeOne((value, metadata) =>
  parsePhoneNumberFromString(value, metadata)
);

const memoizedCountryCodes = memoizeOne((metadata) =>
  getCountryCodes(metadata)
);

const getCountryCode = (props, state = {}) => {
  const existingPhone = memoizedParseNumber(props.value.value, props.metadata);
  if (existingPhone && existingPhone.isValid()) {
    return existingPhone.country;
  }

  return state.countryCode || props.value.countryCode || props.countryCode;
};

const propTypes = {
  countryCode: PropTypes.string,
  countrySelect: PropTypes.bool,
  extendedValues: PropTypes.bool,
  locale: PropTypes.string,
  customModifier: PropTypes.func,
  countryLocaleData: PropTypes.object,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  textMap: PropTypes.shape({
    selectCountry: PropTypes.node,
    enterTelephone: PropTypes.node,
    countryList: PropTypes.object,
  }),
  /** docgen-from-context:<Field/> */
  value: PropTypes.shape({
    raw: PropTypes.string,
    value: PropTypes.string,
    countryCode: PropTypes.string,
  }),
  /** docgen-from-context:<Field/> */
  name: PropTypes.string.isRequired,
  /** docgen-from-context:<Field/> */
  invalid: PropTypes.bool,
  /**
   * docgen-from-context:<Field/>
   * ---
   * proxied to eventBus
   */
  onChange: PropTypes.func,
  /** docgen-from-context:<Field/> */
  onBlur: PropTypes.func,
  /** docgen-from-context:<Field/> */
  describers: PropTypes.string,
  fullWidth: PropTypes.bool,
  metadata: PropTypes.object.isRequired,
  disableCountryCallingCode: PropTypes.bool,
};

const defaultProps = {
  countryCode: 'GB',
  countrySelect: true,
  extendedValues: false,
  locale: 'en',
  marginBottom: 0,
  customModifier: (options) => sortBy(['name'], options),
  textMap: {
    selectCountry: 'Select a country',
    enterTelephone: 'Enter your telephone number',
    countryList: parsedEnCountry,
  },
  fullWidth: false,
  disableCountryCallingCode: false,
};

class TelephoneInputInner extends Component {
  static propTypes = { ...TextInput.propTypes, ...propTypes };

  static defaultProps = {
    ...defaultProps,
    value: {
      raw: '',
      value: '',
      countryCode: '',
    },
  };

  constructor(props) {
    super(props);

    let countryCode = getCountryCode(props);

    // If only raw is set, calculate value and update
    if (!isEmpty(props.value.raw) && isEmpty(props.value.value)) {
      const formattedValues = getFormattedValues(
        props.value.raw,
        countryCode,
        props.extendedValues,
        props.metadata
      );

      props.onChange({ value: formattedValues });
    }

    this.state = {
      countryCode,
      previousPropsValue: props.value.raw,
    };

    // Register language lookup based on prop
    if (props.textMap.countryList && props.locale !== 'en') {
      countries.registerLocale({
        locale: props.locale,
        countries: props.textMap.countryList,
      });
    }
    if (props.countryLocaleData) {
      countries.registerLocale(props.countryLocaleData);
    }

    this.inputRefs = {
      country: createRef(),
      telephone: createRef(),
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.value.raw !== state.previousPropsValue) {
      return {
        countryCode: getCountryCode(props, state),
        previousPropsValue: props.value.raw,
      };
    }

    return null;
  };

  handleCountryChange = (event) => {
    const { value } = this.props;
    event.persist();
    this.setState({ countryCode: event.target.value }, () => {
      const updatedValue =
        value.raw.charAt(0) === '+'
          ? value.raw.substring(1, value.raw.length)
          : value.raw;

      this.handleNumberChange({ value: updatedValue, event });
    });
  };

  hasCountryChanged = (value) => {
    // First, see if we can parse the number
    const phone = memoizedParseNumber(value, this.props.metadata);

    // if no phone number could be parsed
    if (phone) {
      // If so, and the country has changed then return new country code
      if (
        phone.isValid() &&
        phone.isPossible() &&
        this.state.countryCode !== phone.country
      ) {
        // We take the shortName from the lib as it can differentiate between countries when
        // the countryCallingCode is the same e.g. +1 for US and CA
        return {
          countryCode: phone.countryCallingCode,
          shortName: phone.country,
        };
      }

      // If we can't parse the number then we try to determine the country calling code
      // from the partial number that has been input
      let match = checkPartialNumberChangesCountry(
        value,
        this.state,
        this.props.metadata
      );

      if (match) return match;

      // When not using country select, default back to
      // countryCode prop for parsing telephone numbers
      // if parsing fails or country isn't valid
      if (!this.props.countrySelect && !phone.country) {
        this.setState({
          countryCode: this.props.countryCode,
        });
      }
    }

    return false;
  };

  handleNumberChange = ({ value, event }) => {
    const { extendedValues, metadata } = this.props;
    let countryCode = this.state.countryCode;

    // Limit input to number characters and some symbols
    if (!/^[0-9+\-() ]*$/.test(value)) {
      return;
    }

    if (value === '') {
      return this.handleChange({ countryCode, raw: '', value: '' }, event);
    }

    const changedCountry = this.hasCountryChanged(value, metadata);

    if (changedCountry) {
      // Work out which country we've changed to, based on the country code and the details from libphonenumber-js
      this.setState({ countryCode: changedCountry.shortName });
      countryCode = changedCountry.shortName;
    }

    const formattedValue = getFormattedValues(
      value,
      countryCode,
      extendedValues,
      metadata
    );

    return this.handleChange(formattedValue, event);
  };

  handleChange = (value, event) => {
    eventBus.dispatch({
      type: EventBusType.ON_CHANGE,
      component: 'TelephoneInput',
      value,
      name: this.props.name,
    });

    if (this.props.onChange) {
      this.props.onChange({
        value,
        event,
      });
    }
  };

  handleBlur = (e) => {
    if (this.props.onBlur) {
      isBlurringOutsideRefs(e, this.inputRefs).then((result) => {
        if (result) this.props.onBlur(e);
      });
    }
  };

  getCountryOptions = () => {
    if (!this.props.metadata) return [];

    const countryOptions = memoizedCountryCodes(this.props.metadata).map(
      (country) => {
        let countryName = countries.getName(
          country.shortName,
          this.props.locale
        );
        if (!countryName) {
          countryName = `+${country.countryCode}`;
        }
        return { name: countryName, shortName: country.shortName };
      }
    );
    const options = this.props.customModifier(countryOptions).map((country) => {
      return (
        <option key={country.shortName} value={country.shortName}>
          {country.name} +
          {getCountryCallingCode(country.shortName, this.props.metadata)}
        </option>
      );
    });
    return options;
  };

  render() {
    const {
      countrySelect,
      forwardedRef,
      invalid,
      marginBottom,
      textMap,
      name,
      fullWidth,
      metadata,
      disableCountryCallingCode,
      ...rest
    } = omit(
      [
        'locale',
        'countryLocaleData',
        'countryCode',
        'customModifier',
        'extendedValues',
        'value',
        'onChange',
        'onBlur',
      ],
      this.props
    );

    this.inputRefs.telephone = forwardedRef || this.inputRefs.telephone;
    return (
      <div
        className={cn(
          styles.TelephoneInput,
          getMarginBottomClass(marginBottom)
        )}
      >
        {countrySelect && (
          <div
            className={cn(styles['Select-container'], {
              'Select-container--disableCountryCallingCode': disableCountryCallingCode,
            })}
          >
            <label
              className={styles.visuallyHidden}
              htmlFor={`${name}-country-select-field`}
            >
              {textMap.selectCountry}
            </label>
            {!disableCountryCallingCode && (
              <select
                className={cn(styles['Select'], {
                  [styles['Select--invalid']]: invalid,
                })}
                name={`${name}-country-select`}
                id={`${name}-country-select-field`}
                onChange={this.handleCountryChange}
                onBlur={this.handleBlur}
                value={this.state.countryCode}
                ref={this.inputRefs.country}
              >
                {this.getCountryOptions()}
              </select>
            )}

            <div
              aria-hidden="true"
              className={cn(styles['Value'], {
                [styles['Value--invalid']]: invalid,
              })}
              role="presentation"
            >
              <div>
                <span className={cn(styles['PlusSign'])}>+</span>
                {getCountryCallingCode(this.state.countryCode, metadata)}
              </div>
            </div>
            {!disableCountryCallingCode && (
              <div className={styles.Icon}>
                <IconChevronDown marginBottom={0} />
              </div>
            )}
          </div>
        )}

        <div
          className={cn(styles.Telephone, { [styles.fullWidth]: fullWidth })}
        >
          {countrySelect && (
            <label
              className={styles.visuallyHidden}
              htmlFor={`${name}-telephone-field`}
            >
              {textMap.enterTelephone}
            </label>
          )}
          <TextInput
            fullWidth
            invalid={invalid}
            onChange={this.handleNumberChange}
            onBlur={this.handleBlur}
            type="tel"
            ref={this.inputRefs.telephone}
            name={countrySelect ? `${name}-telephone` : name}
            value={this.props.value.raw}
            {...rest}
          />
        </div>
      </div>
    );
  }
}

TelephoneInputInner.displayName = 'TelephoneInputInner';

TelephoneInputInner.inputPropTypes = omit('metadata', propTypes);

export default TelephoneInputInner;
