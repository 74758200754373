import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withContext from '../../utils/withContext';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';
import { Consumer } from './context';
import { IconStatusYesThick } from '../Icon';

import styles from './list-item.css';

export const ListItemParentElementType = {
  OL: 'ol',
  UL: 'ul',
};

export const ListItemType = {
  DISC: 'disc',
  CIRCLE: 'circle',
  DECIMAL: 'decimal',
  DENARY: 'denary',
  LOWER_ALPHA: 'lower-alpha',
  LOWER_ROMAN: 'lower-roman',
  NONE: 'none',
  SQUARE: 'square',
  TICK: 'tick',
};

const ListItem = ({
  type = ListItemType.DISC,
  marginBottom = 2,
  parentElementType = ListItemParentElementType.UL,
  columns,
  largeMinWidth,
  children,
  ...rest
}) => (
  <li
    className={cn(
      styles.ListItem,
      parentElementType === ListItemParentElementType.OL
        ? styles['OrderedList']
        : styles['UnorderedList'],
      {
        [styles.withIcon]: type === ListItemType.TICK,
        [styles.columns]: !!columns,
        [styles.largeMinWidth]: largeMinWidth,
      },
      getMarginBottomClass(marginBottom)
    )}
    {...rest}
  >
    {type !== 'none' && (
      <span
        className={cn(styles.listStyle, styles[type])}
        aria-hidden={type === ListItemType.TICK ? true : false}
      >
        {type === ListItemType.TICK && <IconStatusYesThick inline />}
      </span>
    )}
    <span className={styles.listContent}>{children}</span>
  </li>
);

ListItem.displayName = 'ListItem';

ListItem.propTypes = {
  /** docgen-from-context:<OrderedList/> or <UnorderedList/> */
  type: PropTypes.oneOf([
    ListItemType.DISC,
    ListItemType.CIRCLE,
    ListItemType.DECIMAL,
    ListItemType.DENARY,
    ListItemType.LOWER_ALPHA,
    ListItemType.LOWER_ROMAN,
    ListItemType.NONE,
    ListItemType.SQUARE,
    ListItemType.TICK,
  ]),
  /** docgen-from-context:<OrderedList/> or <UnorderedList/> */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  children: PropTypes.node,
  /** docgen-ignore */
  parentElementType: PropTypes.oneOf([
    ListItemParentElementType.OL,
    ListItemParentElementType.UL,
  ]),
  /** docgen-ignore */
  columns: PropTypes.bool,
  /** docgen-ignore */
  largeMinWidth: PropTypes.bool,
};

export { ListItem };

export default withContext(
  Consumer,
  ({ type, spacing, parentElementType, columns, largeMinWidth }) => ({
    type,
    marginBottom: spacing,
    parentElementType,
    columns,
    largeMinWidth,
  })
)(ListItem);
