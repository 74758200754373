import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import omit from 'lodash/fp/omit';
import {
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
  gridStyles,
  eventBus,
  EventBusType,
} from '@piggybank/core';
import { Consumer } from '../Field/context';
import { Provider } from '../Label/context';

import styles from './radio.css';

const RadioLabel = ({ id, fullWidth, marginBottom, children }) => (
  <label
    className={cn(styles.radioLabel, getMarginBottomClass(marginBottom), {
      [gridStyles['size-12']]: !fullWidth,
      [gridStyles['sizeMedium-7']]: !fullWidth,
      [gridStyles['sizeLarge-4']]: !fullWidth,
    })}
    htmlFor={id}
  >
    <Provider value={{ inLabel: true }}>{children}</Provider>
  </label>
);

RadioLabel.propTypes = {
  id: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  children: PropTypes.node,
};

const RadioInput = ({
  invalid,
  required,
  describers,
  name,
  id,
  value,
  checked,
  onChange,
  forwardRef,
  marginBottom,
  ...rest
}) => (
  <span
    className={cn(styles.inputContainer, getMarginBottomClass(marginBottom))}
  >
    <input
      aria-invalid={invalid}
      aria-describedby={describers}
      className={styles.input}
      type="radio"
      required={required}
      name={name}
      id={id}
      value={value}
      checked={checked}
      onChange={onChange}
      ref={forwardRef}
      {...omit(['children', 'selectedValue', 'fullWidth'], rest)}
    />
    <span
      aria-hidden
      className={cn(styles.inputMask, {
        [styles['inputMask--checked']]: checked,
        [styles['inputMask--invalid']]: invalid,
      })}
    />
  </span>
);

RadioInput.propTypes = {
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  describers: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  forwardRef: PropTypes.object,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

const Radio = React.forwardRef((props, ref) => {
  const { name, value, selectedValue, onChange, children } = props;

  const id = `${name}-${value}-radio-item`;
  const checked = selectedValue !== undefined && value === selectedValue;

  const handleChange = (event) => {
    onChange({ value, event });
    eventBus.dispatch({
      type: EventBusType.ON_CHANGE,
      component: 'Radio',
      value,
      name,
    });
  };

  return children ? (
    <RadioLabel {...props} id={id} checked={checked}>
      <RadioInput
        {...omit(['children', 'marginBottom'], props)}
        id={id}
        checked={checked}
        onChange={handleChange}
        forwardRef={ref}
        marginBottom={0}
      />
      <span className={styles.labelContainer}>{children}</span>
    </RadioLabel>
  ) : (
    <RadioInput
      {...props}
      id={id}
      checked={checked}
      onChange={handleChange}
      forwardRef={ref}
    />
  );
});

Radio.displayName = 'Radio';

Radio.propTypes = {
  /** docgen-from-context:<Fieldset/> */
  name: PropTypes.string.isRequired,
  /** docgen-from-context:<Fieldset/> */
  invalid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  /** docgen-from-context:<Fieldset/> */
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  /**
   * docgen-from-context:<Fieldset/>
   * ---
   * proxied to eventBus
   */
  onChange: PropTypes.func.isRequired,
  /** docgen-from-context:<Fieldset/> */
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  /** docgen-from-context:<Fieldset/> */
  describers: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

Radio.defaultProps = {
  invalid: false,
  marginBottom: 0,
  required: true,
};

export { Radio };

export default withContext(Consumer, (value) => ({
  name: value.name,
  selectedValue: value.value,
  onChange: value.onChange,
  onBlur: value.onBlur,
  invalid: value.invalid,
  describers: value.describers,
}))(Radio);
